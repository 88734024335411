import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faGithub,
  faLinkedinIn,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

const Navigation = () => {
  const [isTop, setIsTop] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      // Check if we are at the very top of the page
      setIsTop(window.scrollY === 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <nav
      className={`absolute top-0 left-0 z-30 w-full transition-opacity duration-300  border-b-2 border-gray-200 border-dashed bg-gray-50 `}
    >
      <div className="flex justify-center p-4">
        {/* Left Section: Empty (or add a logo here in the future) */}
        <div className="flex-1"></div>

        {/* Center Section: Navigation Links */}
        <div className="flex justify-center flex-1">
          <ul className="flex flex-row gap-10">
            {["home", "blog", "portfolio"].map((link) => (
              <li key={link}>
                <Link
                  to={link === "home" ? "/" : `/${link}`}
                  className="pl-6 text-xl font-bold cursor-pointer hover:text-sky-700"
                >
                  {link.charAt(0).toUpperCase() + link.slice(1)}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <div className="justify-end flex-1 hidden gap-4 pr-4 md:flex">
          <a
            href="https://facebook.com/yourprofile"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              icon={faFacebookF}
              className="text-xl hover:text-blue-600"
            />
          </a>
          <a
            href="https://instagram.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              icon={faInstagram}
              className="text-xl hover:text-pink-500"
            />
          </a>
          <a
            href="https://twitter.com/srimanp201"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              icon={faTwitter}
              className="text-xl hover:text-blue-400"
            />
          </a>
          <a
            href="https://github.com/srimancode"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              icon={faGithub}
              className="text-xl hover:text-gray-700"
            />
          </a>
          <a
            href="https://www.linkedin.com/in/srimanujhanthachhen/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              icon={faLinkedinIn}
              className="text-xl hover:text-blue-700"
            />
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
