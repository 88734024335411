import React from "react";

const AWSInterview = () => {
  return (
    <div className="container px-6 py-10 pt-20 mx-auto">
      <h1 className="text-4xl font-bold">
        Preparing for AWS Solutions Architect Intern Interview
      </h1>
      <p className="text-gray-500">Feb 1, 2025</p>
      <img
        src="https://images.glints.com/unsafe/glints-dashboard.oss-ap-southeast-1.aliyuncs.com/company-logo/f8441721c066e21e0fd3375e7ca9eee7.png"
        alt="AWS Interview"
        className="w-full my-4 rounded-lg"
      />
      <p className="mt-4 text-gray-700">
        This blog shares my experience and preparation for the AWS Solutions
        Architect Intern interview at Amazon...
      </p>
    </div>
  );
};

export default AWSInterview;
