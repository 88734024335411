import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTwitter,
  faLinkedin,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";

function Footer() {
  return (
    <div className="flex justify-center pt-4">
      <footer className="flex flex-col items-center justify-around w-full md:flex-row">
        <h1 className="text-lg font-medium md:p-5">
          © 2025 Sriman Ujhanthachhen
        </h1>
        <div className="flex items-center gap-5 p-5">
          <a href="https://facebook.com/yourprofile" className="mr-2 ">
            <FontAwesomeIcon icon={faFacebook} className="text-xl" />
          </a>
          <a href="https://twitter.com/srimanp201" className="mr-2 ">
            <FontAwesomeIcon icon={faTwitter} className="text-xl" />
          </a>
          <a
            href="https://www.linkedin.com/in/srimanujhanthachhen/"
            className=""
          >
            <FontAwesomeIcon icon={faLinkedin} className="text-xl" />
          </a>
          <a href="https://github.com/SrimanCode" className="text-gray-50">
            <FontAwesomeIcon icon={faGithub} className="text-xl" />
          </a>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
